<template>
  <div class="checkout-work">
    <figure class="checkout-work__card checkout-work__figure">
      <img :src="img" />
    </figure>

    <div class="checkout-work__title">
      <div class="heading-size-4">{{ title }}</div>
      <div class="heading-size-4">{{ artist }}</div>
    </div>
    <div v-if="$slots.default" class="checkout-work__step">
      <slot></slot>
    </div>
  </div>
</template>

<script>
// import FramesWork from '@/components/Organisms/FramesWork';
export default {
  name: 'CheckoutWork',
  // components: {
  //   FramesWork,
  // },
  props: {
    id: String,
    img: String,
    artist: String,
    title: String,
    coordinates: Array,
  },
  data() {
    return {
      x: 0,
      y: 0,
    };
  },
  watch: {
    coordinates: function(val) {
      this.x = val[0];
      this.y = val[1];
    },
  },
};
</script>

<style lang="scss" scoped>
.checkout-work {
  $this: &;
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'title title'
    'figure figure'
    'step step';

  &__title,
  &__figure,
  &__step {
    border-radius: 4px;
    background: var(--color-white);
    padding: 12px;
  }
  &__title {
    grid-area: title;
  }
  &__step {
    grid-area: step;
  }
  &__figure {
    grid-area: figure;
    max-height: 280px;
    // max-width: 400px;
    display: flex;
    justify-content: center;

    img {
      object-fit: contain;
    }
  }
}
</style>
